


































































































import Vue, { PropType } from "vue";
import moment from "moment";
import EventClient from "@/api/events/EventClient";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassMinimalResponse } from "@/api/school-class/types/Responses";
import { AllItems } from "@/api/AbstractClient";
import { AbstractEventResponse } from "@/api/events/types/Responses";
import EventDetail from "@/components/events/calendar/EventDetail.vue";
import _ from "lodash";

export default Vue.extend({
  name: "SmallEventsCalendar",
  components: { EventDetail },
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyMonthEvents: false,
    isBusyDayEvents: false,
    // date picker data
    month: moment().format("YYYY-MM"),
    day: moment().format("YYYY-MM-DD"),
    // events
    monthEvents: [] as AbstractEventResponse[],
    wholeDayEvents: [] as AbstractEventResponse[],
    timedDayEvents: [] as AbstractEventResponse[],
    // dialog
    dialog: false,
    event: null as AbstractEventResponse | null,
  }),
  watch: {
    day() {
      this.fetchDayEvents();
    },
    month() {
      this.fetchMonthEvents();
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyMonthEvents || this.isBusyDayEvents;
    },
  },
  methods: {
    onSelectEvent(event: AbstractEventResponse): void {
      this.event = event;
      this.dialog = true;
    },
    onCloseEvent(): void {
      this.event = null;
      this.dialog = false;
    },
    dots(date: string): string[] | false {
      const events = this.monthEvents.filter((event: AbstractEventResponse): boolean => event.datetime.isSame(moment(date), "day"));
      if (events.length === 0) return false;
      return _.uniq(events.map((event) => event.color));
    },
    fetchDayEvents(): void {
      const filter = "eq:date:\"" + moment(this.day).set("hours", 0).set("minutes", 0).set("seconds", 0).format() + "\"";

      this.isBusyDayEvents = true;
      EventClient.getEvents(this.schoolClass.id, this.child.id, new AllItems(filter))
        .then((response) => {
          this.wholeDayEvents = [];
          this.timedDayEvents = [];

          // whole day events
          response.items.filter((event: AbstractEventResponse): boolean => !event.is_timed)
            .forEach((event: AbstractEventResponse): number => this.wholeDayEvents.push(event));

          // timed event
          response.items.filter((event: AbstractEventResponse): boolean => event.is_timed)
            .sort((a: AbstractEventResponse, b: AbstractEventResponse): number => a.datetime.isAfter(b.datetime) ? 1 : -1)
            .forEach((event: AbstractEventResponse): number => this.timedDayEvents.push(event));
        })
        .finally(() => {
          this.isBusyDayEvents = false;
        });
    },
    fetchMonthEvents(): void {
      const firstDay = moment(this.month).startOf("month").format("DD");
      const lastDay = moment(this.month).endOf("month").format("DD");
      const month = moment(this.month).format("MM");
      const year = moment(this.month).format("YYYY");

      const dateFrom = [year, month, firstDay].join("-");
      const dateTo = [year, month, lastDay].join("-");

      const filter = [
        "gte:date:\"" + moment(dateFrom).set("hours", 0).set("minutes", 0).set("seconds", 0).format() + "\"",
        "lte:date:\"" + moment(dateTo).set("hours", 0).set("minutes", 0).set("seconds", 0).format() + "\"",
      ].join(";");

      this.isBusyMonthEvents = true;
      EventClient.getEvents(this.schoolClass.id, this.child.id, new AllItems(filter))
        .then((response) => {
          this.monthEvents = response.items;
        })
        .finally(() => {
          this.isBusyMonthEvents = false;
        });
    },
  },
  created(): void {
    this.fetchDayEvents();
    this.fetchMonthEvents();
  },
});
