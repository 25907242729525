












import Vue, { PropType } from "vue";
import { FamilyResponse } from "@/api/family/types/Responses";
import ChildCard from "@/components/family/card/ChildCard.vue";

export default Vue.extend({
  name: "ChildrenList",
  components: { ChildCard },
  props: {
    family: {
      type: Object as PropType<FamilyResponse>,
      required: true,
    },
    cols: {
      type: Number,
      default: () => 4,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },
});
