






































































import UserClient from "@/api/user/UserClient";
import PinCode from "@/components/pin-code/PinCode.vue";
import { Identity } from "@/utils/Identity";
import Vue, { PropType } from "vue";
import { PinCodeResponse, UserDetailResponse } from "@/api/user/types/Responses";
import EditAuthorizedPersonForm from "@/components/users/form/EditAuthorizedPersonForm.vue";

export default Vue.extend({
  name: "AuthorizedPersonCard",
  components: { PinCode, EditAuthorizedPersonForm },
  props: {
    authorizedPerson: {
      type: Object as PropType<UserDetailResponse>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    showPinCodeDialog(value: boolean): void {
      if (value && this.pinCode === null) {
        this.fetchPinCode();
      }
    },
  },
  data: () => ({
    // loading
    isBusyPinCode: false,
    isBusyRegeneratePinCode: false,
    // dialog
    updateAuthorizedPersonDialog: false,
    showPinCodeDialog: false,
    // PIN code data
    pinCode: null as PinCodeResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyPinCode || this.isBusyRegeneratePinCode;
    },
    isImpersonated(): boolean {
      return this.$store.getters["ApiToken/getIsImpersonated"];
    },
  },
  methods: {
    onRegeneratePinCode(): void {
      this.isBusyRegeneratePinCode = true;
      UserClient.regenerateAuthorizedPersonPinCode(this.authorizedPerson.id)
        .then(() => {
          this.$snackbarSuccess(this.$t("users.pin_code_regenerated_successfully"));
          this.fetchPinCode();
        })
        .finally(() => {
          this.isBusyRegeneratePinCode = false;
        });
    },
    onChangeIdentity(): void {
      Identity.onChangeIdentity(this.authorizedPerson.id)
        .then((response) => {
          // snackbar
          this.$snackbarSuccess(this.$t("layout.identity_changed"));
          // go to user's dashboard
          this.$router.push({ name: "dashboard" });
          return response;
        });
    },
    fetchPinCode(): void {
      this.isBusyPinCode = true;
      UserClient.getUsersPinCode(this.authorizedPerson.id)
        .then((response) => {
          this.pinCode = response;
        })
        .finally(() => {
          this.isBusyPinCode = false;
        });
    },
    onDelete(): void {
      this.updateAuthorizedPersonDialog = false;
      this.$emit("onDelete");
    },
  },
});
