













import Vue, { PropType } from "vue";
import { FamilyResponse } from "@/api/family/types/Responses";
import AuthorizedPersonCard from "@/components/family/card/AuthorizedPersonCard.vue";

export default Vue.extend({
  name: "AuthorizedPersonsList",
  components: { AuthorizedPersonCard },
  props: {
    family: {
      type: Object as PropType<FamilyResponse>,
      required: true,
    },
    cols: {
      type: Number,
      default: () => 4,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },
});
