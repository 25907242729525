import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { ChildrenCountResponse, ChildResponse } from "@/api/child/types/Responses";
import { CreateChildRequest, UpdateChildRequest } from "@/api/child/types/Requests";

export default class ChildClient extends AbstractClient {
  /**
   * Perform get children by school request
   * @param schoolId school ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getChildrenBySchool(schoolId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<ChildResponse>> {
    return AbstractClient.get("/api/children/by-school/" + schoolId, pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(ChildResponse, response.data));
  }

  /**
   * Perform get children by school class request
   * @param schoolClassId school class ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getChildrenBySchoolClass(schoolClassId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<ChildResponse>> {
    return AbstractClient.get("/api/children/by-class/" + schoolClassId, pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(ChildResponse, response.data));
  }

  /**
   * Perform get child request
   * @param childId child ID
   */
  static getChild(childId: number): Promise<ChildResponse> {
    return AbstractClient.get("/api/children/" + childId)
      .then((response) => new ChildResponse(response.data));
  }

  /**
   * Perform get children including temporary locations request
   * @param schoolClassId school class ID
   * @param date date
   * @param pagination pagination/filters/sort configuration
   */
  static getChildrenIncludingTemporaryLocations(schoolClassId: number, date: moment.Moment, pagination: FilterQueryParameters): Promise<PaginatedResponse<ChildResponse>> {
    return AbstractClient.get("/api/children/by-class/" + schoolClassId + "/including-temporary-locations/" + date.format("YYYY-MM-DD"), pagination)
      .then((response) => PaginatedResponse.fromResponseData(ChildResponse, response.data));
  }

  /**
   * Perform create child request
   * @param schoolClassId school class ID
   * @param request create child request
   */
  static createChild(schoolClassId: number, request: CreateChildRequest): Promise<ChildResponse> {
    return AbstractClient.post("/api/children/by-class/" + schoolClassId, request)
      .then((response) => new ChildResponse(response.data));
  }

  /**
   * Perform update child request
   * @param childId child ID
   * @param request update child request
   */
  static updateChild(childId: number, request: UpdateChildRequest): Promise<ChildResponse> {
    return AbstractClient.patch("/api/children/" + childId, request)
      .then((response) => new ChildResponse(response.data));
  }

  /**
   * Perform get children count response
   */
  static getChildrenCount(): Promise<ChildrenCountResponse> {
    return AbstractClient.get("/api/children/count")
      .then((response) => new ChildrenCountResponse(response.data));
  }
}
