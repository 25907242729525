



















import Vue, { PropType } from "vue";
import { FamilyResponse } from "@/api/family/types/Responses";

export default Vue.extend({
  name: "FamilyListItem",
  props: {
    family: {
      type: Object as PropType<FamilyResponse>,
      required: true,
    },
  },
});
