


































import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import EditChildForm from "@/components/child/form/EditChildForm.vue";

export default Vue.extend({
  name: "ChildCard",
  components: { EditChildForm },
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    // dialog
    editChildDialog: false,
  }),
  methods: {
    onDelete(): void {
      this.editChildDialog = false;
      this.$emit("onDelete");
    },
  },
});
