























































import { PinCodeResponse } from "@/api/user/types/Responses";
import UserClient from "@/api/user/UserClient";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "PinCode",
  props: {
    pinCode: {
      type: null as unknown as PropType<PinCodeResponse | null>,
      required: true,
    },
    allowRegeneration: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isBusy: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    // show PIN code
    showPinCode: false,
  }),
  computed: {
    pinCodeAsString(): string {
      if (this.pinCode === null) return "";
      return this.showPinCode
        ? this.pinCode.pin_code
        : "&bull;".repeat(this.pinCode.pin_code.length);
    },
  },
  methods: {
    onRegenerate(): void {
      if (!confirm(this.$t("events.quick_actions.show_my_pin_code.confirm_regenerate_pin_code").toString())) {
        return;
      }
      this.$emit("onRegenerate");
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
