













































































































import Vue, { PropType } from "vue";
import { AuthorizedPersonTypeResponse, UserDetailResponse } from "@/api/user/types/Responses";
import { TranslateResult } from "vue-i18n";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import AuthorizedPersonType from "@/enums/AuthorizedPersonType";
import EnumerationClient from "@/api/enum/EnumerationClient";
import { AuthorizedPersonRequest } from "@/api/user/types/Requests";

export default Vue.extend({
  name: "AuthorizedPersonForm",
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    isBusyDelete: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    defaults: {
      type: Object as PropType<UserDetailResponse>,
      required: false,
    },
  },
  data: () => ({
    // loading
    isBusyAuthorizedPersonTypes: false,
    // enums
    authorizedPersonTypes: [] as AuthorizedPersonTypeResponse[],
    // form data
    form: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      authorized_person_type: AuthorizedPersonType.LEGAL_REPRESENTATIVE,
    } as AuthorizedPersonRequest,
  }),
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      email: {
        required: requiredIf(function(this: any): boolean {
          return this.form.authorized_person_type === AuthorizedPersonType.LEGAL_REPRESENTATIVE;
        }),
        email,
      },
      phone: {},
      authorized_person_type: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyDelete || this.isBusyAuthorizedPersonTypes;
    },
    dialogTitle(): TranslateResult {
      return !this.defaults
        ? this.$t("users.create_authorized_person")
        : this.$t("users.edit_authorized_person");
    },
    firstNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.first_name?.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push(this.$t("users.validation.first_name.required"));
      return errors;
    },
    lastNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.last_name?.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push(this.$t("users.validation.last_name.required"));
      return errors;
    },
    emailErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.email?.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("users.validation.email.required"));
      !this.$v.form.email.email && errors.push(this.$t("users.validation.email.email"));
      return errors;
    },
    phoneErrors(): TranslateResult[] {
      return [];
    },
    authorizedPersonTypeErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.authorized_person?.$dirty) return errors;
      !this.$v.form.authorized_person.authorized_person_type.required && errors.push(this.$t("users.validation.authorized_person_type.required"));
      return errors;
    },
    isMe(): boolean {
      return this.defaults && this.defaults.id === this.$store.getters["User/getId"];
    },
    disableNameChange(): boolean {
      return !this.$isAdmin() && this.defaults !== undefined;
    },
  },
  methods: {
    initDefaults(): void {
      if (this.defaults) {
        this.form.first_name = this.defaults.first_name;
        this.form.last_name = this.defaults.last_name;
        this.form.email = this.defaults.email;
        this.form.phone = this.defaults.phone;
        this.form.authorized_person_type = this.defaults.authorized_person?.authorized_person_type.id ?? AuthorizedPersonType.LEGAL_REPRESENTATIVE;
      }
    },
    fetchAuthorizedPersonTypes(): void {
      this.isBusyAuthorizedPersonTypes = true;
      EnumerationClient.getAuthorizedPersonTypes()
        .then((response) => {
          this.authorizedPersonTypes = response;
        })
        .finally(() => {
          this.isBusyAuthorizedPersonTypes = false;
        });
    },
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: AuthorizedPersonRequest = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        phone: this.form.phone,
        authorized_person_type: this.form.authorized_person_type,
      };

      this.$emit("onSubmit", request);
    },
    onDelete(): void {
      this.$emit("onDelete");
    },
    onClose(): void {
      this.$parent.$emit("onClose");
    },
  },
  created(): void {
    this.fetchAuthorizedPersonTypes();
    this.initDefaults();
  },
});
