import SecurityClient from "@/api/security/SecurityClient";
import { LoginAsRequest } from "@/api/security/types/Requests";
import { ApiTokenResponse } from "@/api/security/types/Responses";
import { ChildToSchoolClassSettingResponse } from "@/api/user/types/Responses";
import UserClient from "@/api/user/UserClient";
import PollingService from "@/service/PollingService";
import store from "@/store";

const Identity = {
  onChangeIdentity(userId: number): Promise<ApiTokenResponse> {
    const request: LoginAsRequest = { user: userId };
    return SecurityClient.loginAs(request).then((loginResponse) => {
      UserClient.getMyProfile(loginResponse.api_token).then(
        (myProfileResponse) => {
          store.commit("User/setData", {
            id: myProfileResponse.id,
            firstName: myProfileResponse.first_name,
            lastName: myProfileResponse.last_name,
            email: myProfileResponse.email,
            roles: myProfileResponse.roles,
            avatarUrl: myProfileResponse.avatar_url,
          });
          // from login response
          store.commit("ApiToken/setApiTokenImpersonated", loginResponse.api_token);
          store.commit("ApiToken/setExpiresAtImpersonated", loginResponse.expires_at);
          store.commit("ApiToken/setIsImpersonatedImpersonated", loginResponse.is_impersonated);
          // from my profile response (user data)
          store.commit("Setting/setSelectedSchool", myProfileResponse.setting.selected_school);
          store.commit("Setting/setSelectedSchoolYear", myProfileResponse.setting.selected_school_year);
          store.commit("Setting/setSelectedSchoolClass", myProfileResponse.setting.selected_school_class);
          store.commit("Family/setSelectedChild", { selectedChild: myProfileResponse.setting.selected_child });
          // from my profile response (founder data)
          if (myProfileResponse.founder) {
            store.commit("Stripe/setStripeCustomerId", myProfileResponse.founder.stripe_customer_id);
            store.commit("Stripe/setStripePriceId", myProfileResponse.founder.stripe_price_id);
            store.commit("Stripe/setStripeSubscriptionId", myProfileResponse.founder.stripe_subscription_id);
          }
          myProfileResponse.setting.child_to_school_class_settings.forEach(
            (childToSchoolClass: ChildToSchoolClassSettingResponse): void => {
              store.commit("Family/setChildToSchoolClass", {child: childToSchoolClass.child, schoolClass: childToSchoolClass.school_class});
            }
          );
          // reset polling
          PollingService.getInstance().reset();
        }
      );
      return loginResponse;
    });
  },
};

export { Identity };
