var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.authorizedPerson.full_name)+" "),(_vm.$isAdmin() && !_vm.isImpersonated)?[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.authorizedPerson.isFounder() || _vm.authorizedPerson.isAdmin() || _vm.$store.getters['ApiToken/getIsImpersonated'],"icon":""},on:{"click":_vm.onChangeIdentity}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-login")])],1)]}}],null,false,266157706)},[_c('span',[_vm._v(_vm._s(_vm.$t("users.login_as")))])])]:_vm._e()],2),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.authorizedPerson.authorized_person.authorized_person_type.name)+" ")]),_c('v-card-text',[_c('p',{staticClass:"mb-2"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-email-outline")]),_vm._v(" "+_vm._s(_vm.authorizedPerson.email ? _vm.authorizedPerson.email : "-")+" ")],1),_c('p',{staticClass:"mb-0"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-phone-outline")]),_vm._v(" "+_vm._s(_vm.authorizedPerson.phone ? _vm.authorizedPerson.phone : "-")+" ")],1)]),(!_vm.readonly)?_c('v-card-actions',[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t("button.edit"))+" ")],1)]}}],null,false,1884184194),model:{value:(_vm.updateAuthorizedPersonDialog),callback:function ($$v) {_vm.updateAuthorizedPersonDialog=$$v},expression:"updateAuthorizedPersonDialog"}},[(_vm.updateAuthorizedPersonDialog)?_c('edit-authorized-person-form',{attrs:{"authorized-person":_vm.authorizedPerson},on:{"onSubmit":function($event){_vm.updateAuthorizedPersonDialog = false; _vm.$emit('onSubmit')},"onClose":function($event){_vm.updateAuthorizedPersonDialog = false},"onDelete":_vm.onDelete}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-qrcode")]),_vm._v(" "+_vm._s(_vm.$t("button.pin_code"))+" ")],1)]}}],null,false,2054736222),model:{value:(_vm.showPinCodeDialog),callback:function ($$v) {_vm.showPinCodeDialog=$$v},expression:"showPinCodeDialog"}},[(_vm.showPinCodeDialog)?_c('pin-code',{attrs:{"is-busy":_vm.isBusy,"pin-code":_vm.pinCode,"allow-regeneration":_vm.$isAuthorizedPerson()},on:{"onClose":function($event){_vm.showPinCodeDialog = false},"onRegenerate":_vm.onRegeneratePinCode}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }