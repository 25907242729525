
















































































































































import Vue, { PropType } from "vue";
import _ from "lodash";
import FamilyClient from "@/api/family/FamilyClient";
import { AllItems } from "@/api/AbstractClient";
import { FamilyResponse } from "@/api/family/types/Responses";
import { ChildRequest } from "@/api/child/types/Requests";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import FamilyListItem from "@/components/users/form/misc/FamilyListItem.vue";
import { ChildResponse } from "@/api/child/types/Responses";
import moment from "moment";

export default Vue.extend({
  name: "ChildForm",
  components: { FamilyListItem },
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    defaults: {
      type: Object as PropType<ChildResponse>,
      required: false,
    },
  },
  data: () => ({
    // loading
    isBusyFamilies: 0,
    // form data
    form: {
      first_name: "",
      last_name: "",
      birthdate: "",
      family: null as number | null,
      color: "",
    } as ChildRequest,
    // enums
    families: [] as FamilyResponse[],
    // filter families by
    filterFamiliesBy: "",
    // birthdate date picker dialog
    birthdateDialog: false,
  }),
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      birthdate: { required },
      family: { required },
      color: { required },
    },
  },
  watch: {
    filterFamiliesBy: _.debounce(function(this: any, value?: string): void {
      if (value && value.length > 0) {
        this.fetchFamilies();
      } else {
        this.families = [];
      }
    }, 250),
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    dialogTitle(): TranslateResult {
      return !this.defaults
        ? this.$t("children.create_child")
        : this.$t("children.edit_child");
    },
    firstNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.first_name?.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push(this.$t("children.validation.first_name.required"));
      return errors;
    },
    lastNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.last_name?.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push(this.$t("children.validation.last_name.required"));
      return errors;
    },
    birthdateErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.birthdate?.$dirty) return errors;
      !this.$v.form.birthdate.required && errors.push(this.$t("children.validation.birthdate.required"));
      return errors;
    },
    familyErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.family?.$dirty) return errors;
      !this.$v.form.family.required && errors.push(this.$t("children.validation.family.required"));
      return errors;
    },
    birthdateFormatted(): string {
      return this.form.birthdate ? moment(this.form.birthdate).format("LL") : "";
    },
    swatches(): string[][] {
      return [
        ["#FF9AA2"],
        ["#FFB7B2"],
        ["#FFDAC1"],
        ["#E2F0CB"],
        ["#B5EAD7"],
        ["#C7CEEA"],
      ];
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: ChildRequest = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        birthdate: this.form.birthdate,
        family: this.form.family,
        color: this.form.color,
      };

      this.$emit("onSubmit", request);
    },
    onClose(): void {
      this.$parent.$emit("onClose");
    },
    fetchFamilies(): void {
      if (this.defaults) {
        return;
      }
      let filter = "";
      if (!_.isEmpty(this.filterFamiliesBy)) {
        filter = "fulltext:\"" + this.filterFamiliesBy + "\"";
      }
      this.isBusyFamilies += 1;
      FamilyClient.getFamilies(new AllItems(filter))
        .then((response) => {
          this.families = response.items;
        })
        .finally(() => {
          this.isBusyFamilies -= 1;
        });
    },
    initDefaults(): void {
      if (this.defaults) {
        this.form.first_name = this.defaults.first_name;
        this.form.last_name = this.defaults.last_name;
        this.form.birthdate = this.defaults.birthdate.format("YYYY-MM-DD");
        this.form.family = this.defaults.family_id;
        this.form.color = this.defaults.color;
      }
    },
  },
  created(): void {
    this.fetchFamilies();
    this.initDefaults();
  },
});
