import {
  ActivateAccountRequest, LoginAsRequest,
  LoginRequest,
  PasswordRecoveryConfirmationRequest,
  PasswordRecoveryRequestRequest,
  RegisterRequest, VerifyEmailRequest,
} from "@/api/security/types/Requests";
import AbstractClient from "@/api/AbstractClient";
import { ApiTokenResponse } from "@/api/security/types/Responses";
import { UserDetailResponse, UserResponse } from "@/api/user/types/Responses";
import { AxiosResponse } from "axios";

export default class SecurityClient extends AbstractClient {
  /**
   * Perform login request
   * @param request login request
   */
  static login(request: LoginRequest): Promise<ApiTokenResponse> {
    return AbstractClient.post("/api/security/login", request)
      .then((response) => new ApiTokenResponse(response.data));
  }

  /**
   * Perform login as request
   * @param request login request
   */
  static loginAs(request: LoginAsRequest): Promise<ApiTokenResponse> {
    return AbstractClient.post("/api/security/login-as", request)
      .then((response) => new ApiTokenResponse(response.data));
  }

  /**
   * Perform register request
   * @param request register request
   */
  static register(request: RegisterRequest): Promise<UserResponse> {
    return AbstractClient.post("/api/security/register", request)
      .then((response) => new UserResponse(response.data));
  }

  /**
   * Perform activate account request
   * @param request activate account request
   */
  static activateAccount(request: ActivateAccountRequest): Promise<AxiosResponse> {
    return AbstractClient.post("/api/security/activate", request, { withoutErrorSnackbar: true });
  }

  /**
   * Perform password recovery request
   * @param request password recovery request request
   */
  static passwordRecoveryRequest(request: PasswordRecoveryRequestRequest): Promise<AxiosResponse> {
    return AbstractClient.post("/api/security/password-recovery", request, { withoutErrorSnackbar: true });
  }

  /**
   * Perform password recovery confirmation request
   * @param request password recovery confirmation request
   */
  static passwordRecoveryConfirmation(request: PasswordRecoveryConfirmationRequest): Promise<AxiosResponse> {
    return AbstractClient.post("/api/security/password-recovery/confirm", request, { withoutErrorSnackbar: true });
  }

  /**
   * Perform verify e-mail request
   * @param request verify e-mail request
   */
  static verifyEmail(request: VerifyEmailRequest): Promise<AxiosResponse> {
    return AbstractClient.post("/api/security/verify-email", request, { withoutErrorSnackbar: true });
  }
}
