





























































































































import EventType from "@/enums/EventType";
import Vue, { PropType } from "vue";
import { AbstractEventResponse } from "@/api/events/types/Responses";

export default Vue.extend({
  name: "EventDetail",
  props: {
    event: {
      type: Object as PropType<AbstractEventResponse>,
      required: true,
    },
    hideName: {
      type: Boolean,
      default: () => false,
    },
    hideDateAndTime: {
      type: Boolean,
      default: () => false,
    },
    hideTemporarilyLocatedTo: {
      type: Boolean,
      default: () => false,
    },
    hidePickUpBy: {
      type: Boolean,
      default: () => false,
    },
    hideCreatedFor: {
      type: Boolean,
      default: () => false,
    },
    hideCreatedBy: {
      type: Boolean,
      default: () => false,
    },
    hideMessage: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    isChildIsPickedUpEvent(): boolean {
      return this.event.type === EventType.CHILD_IS_PICKED_UP;
    },
    isChildTemporarilyLocatedEvent(): boolean {
      return this.event.type === EventType.CHILD_TEMPORARILY_LOCATED;
    },
  },
});
