








import Vue, { PropType } from "vue";
import ChildForm from "@/components/child/form/ChildForm.vue";
import { UpdateChildRequest } from "@/api/child/types/Requests";
import ChildClient from "@/api/child/ChildClient";
import { ChildResponse } from "@/api/child/types/Responses";

export default Vue.extend({
  name: "EditChildForm",
  components: { ChildForm },
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: false,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: UpdateChildRequest): void {
      this.isBusySubmit = true;
      ChildClient.updateChild(this.child.id, request)
        .then((response: ChildResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
